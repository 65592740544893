<template>
  <div>
    <spinner-loader :loading="loadingFundraising" />

    <b-tabs class="mt-2">
      <crowd-fundraising-form
        v-if="!loadingFundraising"
        :submit-functions="{}"
        :disabled="true"
        :crowdfund="crowdfund"
      />
    </b-tabs>
  </div>
</template>
<script>
import CrowdFundraisingForm from '@/common/components/Fundraising/CrowdFundraising/CrowdFundraisingForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'

export default {
  name: 'ViewCrowdFund',
  components: { CrowdFundraisingForm, SpinnerLoader },
  setup() {
    const { getFundraisingProject, crowdfund, loadingFundraising } = FundraisingApi()
    return { getFundraisingProject, crowdfund, loadingFundraising }
  },
  created() {
    this.getFundraisingProject()
  },
}
</script>
<style lang="scss">

</style>
